<template>
  <base-section
    id="features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-monitor-dashboard',
          title: 'Десктоп приложения',
          text: 'Разрабатываем и сопровождаем десктоп-приложения под Windows, Linux и MacOS',
        },
        {
          icon: 'mdi-web',
          title: 'Веб-приложения',
          text: 'Мы не используем CMS. Только разработка на .Net, GoLang, Python, NodeJS, VueJS, ReactJS',
        },
        {
          icon: 'mdi-cellphone-text',
          title: 'Мобильные приложения',
          text: 'Мы создаем как нативные так и кроссплатформенные бизнес-приложения для мобильных устройств',
        },
        {
          icon: 'mdi-puzzle-outline',
          title: 'UI/UX Дизайн',
          text: 'Осуществляем проектирование UX моделей, создаем макеты интерфейса для десктоп, мобильных и веб приложений, разрабатываем графику, выполняем адаптивный дизайн и верстку',
        },
      ],
    }),
  }
</script>
